import React from "react"
import { Link } from "gatsby"
import * as css from "./postlist.module.scss"
import Tags from "../molecules/tags"
export default class PostList extends React.Component {
  render() {
    const { posts } = this.props
    if (posts.length === 0) {
      return (
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      )
    }


    return (
      <div>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <article
              key={post.fields.slug}
              className={css.post}
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <Link to={post.fields.slug} itemProp="url" className={css.url}>
                  <h2 className={css.title} itemProp="headline">{title}</h2>
                </Link>
                <small className={css.date}>{post.frontmatter.date}</small>
                <Tags tags={post.frontmatter.tags} />
              </header>
              <section>
                <p className={css.excerpt}
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
            </article>
          )
        })}
      </div>)
  }
}